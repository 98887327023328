<template>
  <scrollable-container>
    <tabset :tabs="tabs" />
    <keep-alive>
      <router-view />
    </keep-alive>
  </scrollable-container>
</template>

<script>
import Tabset from '@/components/common/tabs/tabset.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';

export default {
  components: {
    Tabset,
    ScrollableContainer,
  },
  data: () => ({
    tabs: [
      {
        title: 'Статистика печати по документам',
        route: './docStat',
      },
      {
        title: 'Статистика печати по принтерам',
        route: './printStat',
        sortVariableName: 'PrintStatSortState',
      },
      {
        title: 'Статистика печати по пользователям',
        route: './userStat',
      },
    ],
  }),
};
</script>
